import { UsuarioModel } from '@/models/UsuarioModel';
import { defineStore } from 'pinia';

export const UserStore = defineStore('UserStore', {
  state: () => ({
    user: {} as UsuarioModel | null
  }), 
  actions: {
    setUser(User: UsuarioModel) {
        this.user = User
    },
    deleteUser(){
        this.user = null
    }
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
  },
});
